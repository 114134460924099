<template>
  <div>
    <function-entry></function-entry>
    <!-- <page-title v-if="!hideTitle"></page-title> -->
    <b-row class="pt-1">
      <b-col cols="12">
        <b-card no-body>
          <b-card-header>
            <div class="d-flex align-items-center">
              <feather-icon class="mr-1" icon="FilterIcon" />
              {{ $t("Filters") }}
            </div>
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-col cols="4">
                <p style="margin-bottom: 0.5rem">{{ $t("Choose a date") }}</p>
                <flat-pickr
                  v-model="filterValues.time"
                  :config="{
                    time_24hr: true,
                    mode: 'range',
                    locale: $i18n.locale === 'cn' ? 'zh' : 'en',
                  }"
                  class="form-control"
                />
              </b-col>
              <b-col cols="4">
                <p style="margin-bottom: 0.5rem">{{ $t("Alarm Type") }}</p>
                <v-select
                  v-model="filterValues.Type"
                  :options="filterOptions.Type"
                  :placeholder="$t('No filter')"
                  class="w-100"
                />
              </b-col>
              <b-col cols="4">
                <p style="margin-bottom: 0.5rem">
                  {{ $t("Alarm Degree") }}
                </p>
                <v-select
                  v-model="filterValues.Degree"
                  :options="filterOptions.Degree"
                  :placeholder="$t('No filter')"
                  class="w-100"
                />
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
      <!-- Table Container Card -->
      <b-col cols="12">
        <b-card no-body>
          <div class="m-2">
            <!-- Table Top -->
            <b-row>
              <!-- Per Page -->
              <b-col
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                cols="6"
                md="6"
              >
                <label style="margin: 0.5rem 0">{{ $t("Show") }}</label>
                <v-select
                  v-model="perPage"
                  :clearable="false"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="perPageOptions"
                  class="per-page-selector d-inline-block mx-50"
                  size="sm"
                />
                <label style="margin: 0.5rem 0">{{ $t("Entries") }}</label>
              </b-col>

              <!-- Search -->
              <b-col cols="6" md="6">
                <div
                  class="d-flex align-middle justify-content-end h-100"
                  style="padding: 0.3rem 0"
                >
                  <download-excel
                    :fetch="downloadData"
                    :fields="exportExcelHeaders(tableColumns)"
                    name="下载查询数据.xls"
                    type="xls"
                  >
                    <b-button size="sm" variant="success">
                      <feather-icon class="mr-50" icon="DownloadIcon" />
                      {{ $t("Download") }}
                    </b-button>
                  </download-excel>
                </div>
              </b-col>
            </b-row>
          </div>

          <b-table
            ref="refAgentListTable"
            :busy="isBusy"
            :empty-text="$t('No Data')"
            :fields="tableColumns"
            :items="fetchAgents"
            :sort-by.sync="sortBy"
            :sort-desc.sync="isSortDirDesc"
            bordered
            class="text-center position-relative"
            hover
            primary-key="agent_id"
            responsive
            show-empty
            small
            striped
          >
            <template v-slot:table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle mr-1"></b-spinner>
                <strong>{{ $t("Loading") }}...</strong>
              </div>
            </template>
            <template #head()="data">
              <span>{{ data.label }}</span>
            </template>
            <!--            label: "故障名称",-->
            <!--            key: "fault_name",-->
            <!--            label: "故障程度",-->
            <!--            key: "degree",-->
            <!--            label: "故障等级",-->
            <!--            key: "primary_class",-->
            <!--            label: "方程名称",-->
            <!--            key: "function_name",-->
            <!--            label: "所属系统",-->
            <!--            key: "system",-->
            <!--            label: "故障时间",-->
            <!--            key: "create_date",-->
            <!-- Column: Index -->
            <template #cell(index)="data">
              <div class="text-nowrap" style="min-width: 2rem">
                <span class="align-text-top text-capitalize">
                  {{ data.index + 1 }}
                </span>
              </div>
            </template>
            <template #cell(fault_name)="data">
              <div style="min-width: 8rem">
                {{ data.item.fault_name }}
              </div>
            </template>
            <template #cell(degree)="data">
              <div style="min-width: 8rem">
                {{ data.item.degree }}
              </div>
            </template>
            <template #cell(primary_class)="data">
              <div style="min-width: 8rem">
                {{ data.item.primary_class }}
              </div>
            </template>
            <template #cell(function_name)="data">
              <div style="min-width: 8rem">
                {{ data.item.function_name }}
              </div>
            </template>
            <template #cell(system)="data">
              <div style="min-width: 8rem">
                {{ data.item.system }}
              </div>
            </template>
            <template #cell(create_date)="data">
              <div style="min-width: 8rem">
                {{ data.item.create_date }}
              </div>
            </template>
            <!-- Column: Index -->
            <template #cell(actions)="data">
              <div style="min-width: 4rem">
                <b-button
                  class="btn-icon"
                  size="sm"
                  variant="warning"
                  @click="showDetails(data.item)"
                >
                  <feather-icon icon="MoreHorizontalIcon" />
                </b-button>
              </div>
            </template>
          </b-table>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
                cols="6"
                sm="6"
              >
                <span class="text-muted">
                  {{
                    `
									  ${$t("Show")}
									  ${dataMeta.from}
									  ${$t("to")}
									  ${dataMeta.to}
									  ${$t("of")}
									  ${total}
									  ${$t("Entries")}
									  `
                  }}
                </span>
              </b-col>
              <!-- Pagination -->
              <b-col
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
                cols="6"
                sm="6"
              >
                <b-pagination
                  v-model="currentPage"
                  :per-page="perPage"
                  :total-rows="total"
                  hide-goto-end-buttons
                  size="sm"
                ></b-pagination>
              </b-col>
            </b-row>
          </div>

          <b-modal
            v-model="detailModal.show"
            title="故障详情"
            size="lg"
            ok-only
            ok-title="查看诊断详情"
            @ok="ToAgentDetail(detailModal.data)"
          >
            <b-row>
              <b-col cols="12">
                <b-card title="基本信息">
                  <div>时间： {{ detailModal.data.create_date }}</div>
                  <div>
                    故障名称：
                    {{ detailModal.data.fault_name }}（
                    {{ detailModal.data.id }} ）
                  </div>
                  <div>
                    函数名称（函数ID）:
                    {{ detailModal.data.function_name }}（
                    {{ detailModal.data.function_id }} ）
                  </div>
                  <div>报警类型： {{ detailModal.data.type }}</div>
                  <div>报警等级： {{ detailModal.data.degree }}</div>
                </b-card>
              </b-col>
              <!--              <b-col cols="6">-->
              <!--                <b-card title="问题描述">-->
              <!--                  <b-card-text style="white-space: pre-line"-->
              <!--                               v-html="detailModal.data.DescDetail ? detailModal.data.DescDetail.replaceAll('，', '<br/>').replaceAll(',', '<br/>') : '暂无数据'"></b-card-text>-->
              <!--                </b-card>-->
              <!--              </b-col>-->
            </b-row>
            <b-card title="问题数据">
              <p v-show="!detailModal.data.data">暂无数据</p>
              <div
                v-show="detailModal.data.data"
                id="chart_agentdetail"
                style="height: 220px"
              ></div>
            </b-card>

            <b-card title="自动诊断">
              <b-card-text
                style="white-space: pre-line"
                v-html="
                  detailModal.data.fault_reason
                    ? detailModal.data.fault_reason
                    : '暂无数据'
                "
              ></b-card-text>
            </b-card>

            <b-card title="推荐措施">
              <b-card-text
                style="white-space: pre-line"
                v-html="
                  detailModal.data.fault_solution
                    ? detailModal.data.fault_solution
                    : '暂无数据'
                "
              ></b-card-text>
            </b-card>
          </b-modal>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  computed,
  onMounted,
  reactive,
  toRefs,
  watch,
} from "@vue/composition-api";
import { showToast } from "@/libs/utils/showToast";
import { getImmediacyResult } from "@/api/projects";
import { getAgentsOptions} from "@/api/front";
import { momentJs } from "@/libs/utils/moment";
import vSelect from "vue-select";
import JsonExcel from "vue-json-excel";
import flatPickr from "vue-flatpickr-component";
import zh from "flatpickr/dist/l10n/zh.js";
import Ripple from "vue-ripple-directive";
import PageTitle from "@/views/layout/PageTitle";
import { routerParams } from "@/libs/utils/routerParams";
import { Line } from "@antv/g2plot";
import { t } from "@core/libs/i18n/utils";
import router from "@/router";
import FunctionEntry from "@/views/front/cabr/diagnosis/dashboard/components/FunctionEntry.vue";

export default {
  directives: {
    Ripple,
  },
  components: {
    FunctionEntry,
    PageTitle,
    vSelect,
    flatPickr,
    downloadExcel: JsonExcel,
  },
  props: ["hide-title"],
  setup() {
    const event = reactive({
      project_id: computed(() => {
        return routerParams("project_id");
      }),
      filterOptions: {},
      filterValues: {
        Degree: null,
        Type: null,
        time: momentJs().format(),
      },
      isEditAgentSidebarActive: false,
      refAgentListTable: null,
      perPage: 10,
      searchQuery: "",
      tableColumns: [
        {
          label: "#",
          key: "index",
        },
        {
          label: "故障名称",
          key: "fault_name",
        },
        {
          label: "故障程度",
          key: "degree",
        },
        {
          label: "故障等级",
          key: "primary_class",
          sortable: true,
        },
        {
          label: "方程名称",
          key: "function_name",
        },
        {
          label: "所属系统",
          key: "system",
        },
        {
          label: "故障时间",
          key: "create_date",
        },
        {
          label: "操作",
          key: "actions",
        },
      ],
      total: 0,
      currentPage: 1,
      perPageOptions: [10, 25, 50, 100],
      sortBy: "create_date",
      isSortDirDesc: true,
      dataMeta: computed(() => {
        const localItemsCount = event.refAgentListTable
          ? event.refAgentListTable.localItems.length
          : 0;
        return {
          from:
            event.perPage * (event.currentPage - 1) + (localItemsCount ? 1 : 0),
          to: event.perPage * (event.currentPage - 1) + localItemsCount,
          of: event.total,
        };
      }),
      detailModal: {
        show: false,
        data: {},
      },
      isBusy: false,
    });
    watch(
      [
        () => event.currentPage,
        () => event.perPage,
        () => event.isEditAgentSidebarActive,
        () => event.searchQuery,
      ],
      () => {
        if (!event.isEditAgentSidebarActive) {
          refetchData();
        }
      }
    );

    watch(event.filterValues, () => {
      refetchData();
    });

    const showDetails = (detail) => {
      event.detailModal.show = true;
      event.detailModal.data = detail;
      const data = JSON.parse(detail.data);
      console.log("data", data);
      // let chartData = [];
      // data.days.map((timeItem, timeIndex) => {
      //   chartData.push({
      //     time: timeItem,
      //     value: data.data[0][timeIndex],
      //     agent: data.legend[0],
      //   });
      // });
      const chartData = [];
      for (let i in data.time) {
        const d = new Date(data.time[i]);
        chartData.push({
          time: `${d.getFullYear()}-${
            d.getMonth() + 1 < 10 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1
          }-${
            d.getDate() + 1 < 10 ? "0" + (d.getDate() + 1) : d.getDate() + 1
          } ${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}`,
          pvvarname: data.pvvarname[i],
          value: data.value[i],
        });
      }
      console.log("chartData", chartData);
      setTimeout(() => {
        const line = new Line("chart_agentdetail", {
          data: chartData,
          padding: "auto",
          xField: "time",
          yField: "value",
          seriesField: "pvvarname",
          xAxis: {
            tickCount: 5,
          },
          yAxis: {
            nice: true,
            grid: {
              line: {
                style: {
                  stroke: "#82868b",
                  lineWidth: 1,
                  lineDash: [2, 8],
                  strokeOpacity: 0.7,
                  shadowColor: "#82868b",
                  shadowBlur: 10,
                  shadowOffsetX: 5,
                  shadowOffsetY: 5,
                  cursor: "pointer",
                },
              },
            },
          },
          slider: {
            start: 0,
            end: 1,
            textStyle: {
              fill: "#eeeeee",
              fillOpacity: 1,
              shadowColor: "black",
              shadowBlur: 1,
            },
            trendCfg: {
              lineStyle: {
                fillOpacity: 1,
              },
            },
          },
        });
        line.render();
      }, 1000);
    };

    const refetchData = () => {
      event.refAgentListTable.refresh();
    };

    const fetchAgents = (ctx, callback) => {
      event.isBusy = true;
      const filters = getFilters();
      const params = {
        project_id: event.project_id,
        pagesize: event.perPage,
        page: event.currentPage,
        sortBy: event.sortBy,
        sortDesc: event.isSortDirDesc ? 1 : 0,
        ...filters,
      };
	  console.log("传过来的参数==",params)
      getList(params)
        .then((response) => {
          const { list, total } = response.data;
          callback(list);
          event.total = total;
          if (!total) {
            showToast(null, "提示", "未查询到数据");
          }
          event.isBusy = false;
        })
        .catch((error) => {
          event.isBusy = false;
          showToast(error);
        });
    };
    /**
     * 获取列表数据
     */
    const getList = async (params) => {
		console.log("请求参数===", params)
      return getImmediacyResult({
        project_id: event.project_id,
        ...params,
      });
    };
    /**
     * 获取参数
     */
    const getOptions = () => {
      getAgentsOptions({
        project_id: event.project_id,
      })
        .then((response) => {
          event.filterOptions = response.data;
        })
        .catch((error) => {
          showToast(error);
        });
    };
    /**
     * 处理导出数据的头部
     * @param fields
     */
    const exportExcelHeaders = (fields) => {
      let headers = {};
      fields.map((item) => {
        if (item.key !== "index" && item.key !== "actions") {
          let header = t(item.label) + "(" + item.key + ")";
          headers[header] = item.key;
        }
      });
      return headers;
    };
    /**
     * 获取筛选条件
     */
    const getFilters = () => {
      let filters = {};
      for (const Key in event.filterValues) {
        let Value = event.filterValues[Key];
        if (!!Value) {
          filters[Key] = Value;
          if (Key === "time") {
            Value = Value.replaceAll(" 至 ", ",").replaceAll(" to ", ",");
            const timeArr = Value.split(",");
            const start = momentJs(timeArr[0])
              .startOf("day")
              .format("YYYY-MM-DD HH:mm:ss");
            const end = momentJs(timeArr[1] || timeArr[0])
              .endOf("day")
              .format("YYYY-MM-DD HH:mm:ss");
            filters[Key] = "(" + start + "," + end + ")";
          }
        }
      }
      return filters;
    };
    /**
     * 下载数据
     * @returns {Promise<*>}
     */
    const downloadData = async () => {
      const filters = getFilters();
      const params = {
        sortBy: event.sortBy,
        sortDesc: event.isSortDirDesc ? 1 : 0,
        ...filters,
      };
      const res = await getList(params);
      if (!res.data.total) {
        showToast(null, "提示", "暂无数据可以下载");
      }
      return res.data.list;
    };

    const ToAgentDetail = (detail) => {
      router.push({ name: "AgentDetail", params: { id: detail.id } });
    };

    onMounted(() => {
      getOptions();
    });
    return {
      ...toRefs(event),
      fetchAgents,
      exportExcelHeaders,
      downloadData,
      zh,
      showDetails,
      ToAgentDetail,
    };
  },
};
</script>

<style lang="scss">
/* .per-page-selector {
  width: 90px;
  }

  .b-form-btn-label-control.dropdown.b-form-datepicker.form-control
  label.form-control {
  padding: 0.3rem 1rem;
  } */

@import "../../../../@core/scss/vue/libs/vue-flatpicker";
@import "../../../../@core/scss/vue/libs/vue-select";
</style>
